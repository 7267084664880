export function doTruncateId (keyword) {
  if (keyword == null) {
    return ''
  } else if (keyword.length > 10) {
    return keyword.slice(0, 10) + '...'
  } else {
    return keyword
  }
}

export function doTruncate (keyword) {
  if (keyword == null) {
    return ''
  } else if (keyword.length > 20) {
    return keyword.slice(0, 20) + '...'
  } else {
    return keyword
  }
}

export function onRowClick (props, column) {
  // props.expand = !props.expand
  if (column === 'id') {
    props.row.expandId = !props.row.expandId
  } else if (column === 'keyword') {
    props.row.expandKeyword = !props.row.expandKeyword
  } else if (column === 'url') {
    props.row.expandUrl = !props.row.expandUrl
  }
}