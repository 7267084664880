<template>
  <q-table
    flat bordered
    :dense="$q.screen.lt.md"
    :rows="profiles"
    :rows-per-page-options="[15,50,100]"
    :columns="profileColumns"
    row-key="id"
    separator="cell"
    :loading="loading"
    :filter="filter"
    @request="onProfileRequest"
    :pagination="pagination"
    @update:pagination="$emit('update:pagination', $event)"
  >
  <template v-slot:body-cell-id="props">
      <q-td :props="props" @click="onRowClick(props, 'id')">
        {{ doTruncateId(props.row.id) }}
        <q-tooltip>
          View full ID
        </q-tooltip>
      <q-dialog v-model="props.row.expandId" max-width="80vw">
      <q-card bordered>
        <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
        <div class="text-h6" style="margin-right: 200px;">Full ID</div>
        <q-space></q-space>
        <q-btn icon="close" flat round dense v-close-popup></q-btn>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section style="max-height: 50vh" class="scroll">
        <div >
          {{ props.row.id }}
        </div>
      </q-card-section>
      </q-card>
    </q-dialog>
    </q-td>
    </template>
    <template v-slot:body-cell-source_url="props">
      <q-td :props="props" @click="onRowClick(props, 'url')">
        {{ doTruncate(props.row.source_url) }}
        <q-tooltip>
          View full URL
        </q-tooltip>
      <q-dialog v-model="props.row.expandUrl" max-width="80vw">
      <q-card bordered>
        <q-card-section class="row items-center q-pb-none" style="margin-bottom: 5px;">
        <div class="text-h6" style="margin-right: 200px;">Full URL</div>
        <q-space></q-space>
        <q-btn icon="close" flat round dense v-close-popup></q-btn>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section style="max-height: 50vh" class="scroll">
        <div >
          {{ props.row.source_url }}
        </div>
      </q-card-section>
      </q-card>
    </q-dialog>
  </q-td>
    </template>
  <template v-slot:body-cell-isActive="props">
    <q-td :props="props">
      <div class="icon-container">
        <q-btn flat
          :icon="hoveredRow === props.row.id ? 'delete' : 'delete'"
          :color="hoveredRow === props.row.id ? 'red' : 'black'"
          @mouseover="hoveredRow = props.row.id"
          @mouseleave="hoveredRow = null"
          @click="deleteRow(props.row)"
        />
        <q-tooltip>
        Delete profile from monitoring
      </q-tooltip>
      </div>
    </q-td>
  </template>
  <template v-slot:header-cell-source="props">
      <q-th :props="props">
        <q-tooltip>
          Click to filter
        </q-tooltip>
        Source
        <q-btn-dropdown
          flat no-caps
          color="white"
          text-color="black"
          dropdown-icon='img:/sort.svg'
          :model-value="dropdown"
          @update:model-value="$emit('update:dropdown', $event)"
        >
      <div class="row no-wrap q-pa-md">
      <div class="column">
        <q-checkbox
          color="black"
          :model-value="allSites"
          @update:model-value="$emit('update:allSites', $event)"
          text-color="purple"
          label="(Select All)"
        >
      </q-checkbox>
        <hr /> <!-- Horizontal line -->
        <div class="column" v-for="(site, index) in sites" :key="index">
        <div class="flex items-center no-wrap">
          <q-checkbox
            color="black"
            v-model="site.active"
          >
          </q-checkbox>
          <WebsiteIcon :website="site.name" class="website-icon margin-right" />
          {{ site.label }}
        </div>
      </div>
      <q-btn color="black" :disable="loading" label="Filter" class="margin-top" @click="updateSiteStatus()"></q-btn>
      </div>
      </div>
      </q-btn-dropdown>
      </q-th>
    </template>
    <template v-slot:top-left>
      <q-btn color="black" :disable="loading || !capacityAvailable" label="Add profile" icon-right="add"  @click="openForm"></q-btn>
      <q-tooltip v-if="!capacityAvailable">
        Your account has a maximum number of allowed crawl requests. Please contact info@openmeasures.io to request more
      </q-tooltip>
      <q-linear-progress size="22px" :indeterminate="loading" :value="this.totalProfilesPercentage" color="accent" class="q-mt-sm">
        <q-tooltip>
        Profiles only count toward the maximum if they are active. Inactive profiles do not count toward the maximum.
        </q-tooltip>
        <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="black" :label="this.totalProfilesLabel"></q-badge>
      </div>
      </q-linear-progress>
      <q-dialog
        :model-value="showForm"
        @update:model-value="$emit('update:showForm', $event)"
      >
      <q-card>
        <q-card-section style="margin-right: 200px;">
        <div class="text-h6">
          Add Profiles
        </div>
      </q-card-section>
      <q-card-section>
        Request profile crawling.
        Multiple profiles can be added by separating them with a new line.
        Make sure to choose the correct profile type and ID type.
      </q-card-section>
      <q-card-section>
        <q-form @submit="addProfile">
          <q-input autogrow outlined
          color="black"
          class="scroll q-mb-md"
          :model-value="newProfile"
          @update:model-value="$emit('update:newProfile', $event)"
          label="Profile"
        />
          <!-- <q-input outlined color="black" class="scroll q-mb-md" v-model="newProfile" label="Profile"/> -->
          <q-select
            outlined
            :model-value="profileOption"
            @update:model-value="$emit('update:profileOption', $event)"
            :options="[
              'User',
              'Group',
              'Channel'
            ]"
            label="Select profile type"
            class="scroll q-mb-md"
          >
            <q-tooltip class="bg-purple" anchor="top middle">
              Profile type is the type of profile you are requesting. For example, if you are requesting a user profile, you would select "User" as the profile type.
            </q-tooltip>
          </q-select>
          <q-select
            outlined
            :model-value="profileId"
            @update:model-value="$emit('update:profileId', $event)"
            :options="[
              {
                label: 'Username',
                value: 'profile'
              },
              {
                label: 'ID',
                value: 'source_id'
              },
              {
                label: 'URL',
                value: 'source_url'
              },
            ]"
            label="Select ID type"
          >
            <q-tooltip class="bg-purple" anchor="top middle">
              This is the profile identifier you are requesting. For example, if you are providing links to the profiles then you will select URL. Open Measures will resolve the identifier regardless of the type for crawling.
            </q-tooltip>
          </q-select>
          <q-checkbox
          class="margin-top"
          color="black"
          :model-value="allSites"
          @update:model-value="$emit('update:allSites', $event)"
          text-color="purple"
          label="(Select All)"
        >
      </q-checkbox>
          <div class="column" v-for="(site, index) in sites" :key="index">
        <div class="flex items-center">
          <q-checkbox
            color="black"
            v-model="site.active"
          >
          </q-checkbox>
          <WebsiteIcon :website="site.name" class="website-icon margin-right" />
          {{ site.label }}
        </div>
        </div>
          <q-btn
          :disable="newProfile === '' || !capacityAvailable || numNewProfiles < 1" type="submit" class="margin-top" icon-right="add" color="black" :loading="loading"
          >
          Add {{ numNewProfiles }} profiles ({{ totalProfilesRemaining }} remaining)
            <template v-slot:loading>
              <q-spinner-grid></q-spinner-grid>
            </template>
          </q-btn>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
  </template>
  <template v-slot:top-right>
      <q-input
        borderless
        dense
        debounce="1000"
        :model-value="filter"
        @update:model-value="$emit('update:filter', $event)"
        :disable="loading"
        placeholder="Search profiles"
      >
        <template v-slot:append>
          <q-icon name="search"></q-icon>
        </template>
        <q-tooltip>
          Search through requested profiles by usernames only
        </q-tooltip>
      </q-input>
    </template>
    <template v-if="isAdmin" v-slot:body-cell-toggleStatus="props">
      <q-td :props="props">
        <q-toggle
            v-model="props.row.isActive"
            color="purple"
            checked-icon="check"
            unchecked-icon="clear"
            @update:model-value="updateProfile(props.row)"
          >
        <q-tooltip>
          Toggle active status
        </q-tooltip>
        </q-toggle>
      </q-td>
    </template>
    <template v-else v-slot:body-cell-toggleStatus="props">
      <q-td :props="props">
        <div class="text-center">
        <q-icon name="done_round" color="green" v-if="props.row.isActive" />
        <q-icon name="clear_round" color="red" v-else />
        </div>
      </q-td>
    </template>
    <template v-slot:header-cell-toggleStatus="props">
      <q-th :props="props">
        {{ props.col.label }}
        <q-icon name="info_outline" color="purple"></q-icon>
        <q-tooltip>
          Profiles show as active when the crawler could discover them. If the profile is not active, it may not exist or the crawler was unable to find it.
        </q-tooltip>
        </q-th>
    </template>
    <template v-slot:body-cell-source="props">
      <q-td :props="props">
          <WebsiteIcon :website="props.row.source" class="website-icon" />
          {{ props.row.source }}
      </q-td>
    </template>
  </q-table>
</template>

<script>
import WebsiteIcon from '@/components/WebsiteIcon'
import { doTruncateId, doTruncate, onRowClick } from '@/helpers/crawlRequests'

export default {
  components: {
    WebsiteIcon,
  },
  props: [
    'loading',
    'profiles',
    'profileColumns',
    'filter',
    'onProfileRequest',
    'pagination',
    'totalProfilesLabel',
    'totalProfilesPercentage',
    'totalProfilesRemaining',
    'showForm',
    'dropdown',
    'capacityAvailable',
    'openForm',
    'isAdmin',
    'profileOption',
    'numNewProfiles',
    'newProfile',
    'sites',
    'addProfile',
    'allSites',
    'profileId',
    'updateSiteStatus',
    'deleteRow',
  ],
  data () {
    return {
      hoveredRow: null,
    }
  },
  methods: {
    doTruncateId,
    doTruncate,
    onRowClick,
  }
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  background-color: var(--color-blue);  /* Change this to your preferred color */
  color: #212529;  /* Change this to your preferred color */
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.icon-container:hover .q-btn.trash-icon {
  visibility: visible;
}

.q-btn.trash-icon {
  visibility: hidden;
}
.add-keyword-button {
  margin-bottom: 20px;
}

$page-padding: 40px;
table {
  width: 100%;
  border-collapse: collapse;
}

button {
  background: none;
  border: none;
  cursor: pointer;

}

.home-header {
  padding: $page-padding var(--spacing-side-padding);
  margin-bottom: 24px;
  width: calc(100vw - 2 * var(--spacing-side-padding));
}

.header {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.margin-right {
  margin-right: 10px; /* Adjust as needed */
}

.margin-top {
  margin-top: 10px; /* Adjust as needed */
}

</style>